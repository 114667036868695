export const SYSTEM_PATH = {
    // LOGIN: '/login',
    // REGISTER: '/register',
    // FORGET_PASSWORD: '/forget-password',
    // SET_PASSWORD: '/set-password',
    // // change password
    // CHANGE_PASSWORD: '/change-password',
    // // dashboard
    HOME: '/'
};

export const MSG = {
    //#region Api message
    'api.response.worng_format': '応答本文は正しい形式ではありません',
    'api.response.no_message': 'エラーメッセージの応答はありません',
    'api.response.server_error':
        'サーバ接続で問題が発生しました。\nお手数ですが、もう一度接続してみて下さい。',
    'api.response.no_network':
        'サーバ接続で問題が発生しました。\nお手数ですが、もう一度接続してみて下さい。',
    'api.response.authorization': '再度ログインして下さい。',
    //#endregion Api message

    //#region inform
    'inform.success.create': '作成は完了しました。',
    'inform.success.update': '編集は完了しました。',
    'inform.success.delete': '削除が完了しました。',
    'inform.success.forget_password': 'メールを送信しました。ご確認ください。',
    //#endregion

    //#region validate error message
    'error.required': '必須項目をご入力ください。',
    'error.email_format': 'メールアドレスの形式が正しくありません。',
    'error.password_format': '８文字以上の半角英数字（１つ以上の大文字含める）',
    'error.furigana_format': 'フリガナでご入力ください。',
    'error.confirm_password_not_match': '新しいパスワード確認が新しいパスワードと一致しないのでもう一度お試しください。'
    //#endregion
};

export const DEFAULT_PAGE_SIZE = 10;
export const PAGE_SIZE_OPTIONS = [10, 50, 100, 500, 1000];
// There are alot of table in system. Example: Document, Form difinition, User, Tenant.
export const TABLE_CODE = {
    DOCUMENT: 0
}

export const SCREEN_MODE = {
    ADD: 0,
    EDIT: 1,
    DETAIL: 2
}

export const WIDTH_COLUMN_SELECT_TABLE = '50px';

export const FORMAT_DATE = 'YYYY/MM/DD';
export const FORMAT_DATE_TIME = 'YYYY/MM/DD HH:mm:ss';

export const AVATAR_DEFAULT_URL = '/images/avatar_default.png';
export const IMAGE_DEFAULT_URL = '/images/image_default.png';

export const GENDER = {
    MALE: {
        key: 0,
        label: '男性'
    },
    FEMALE: {
        key: 1,
        label: '女性'
    }
}

export const MODAL = {
    TITLE_WARNING_MODAL: '確認'
}

export const TEXT = {
    CANCEL: 'キャンセル',
    CREATE: '保存',
    UPDATE: '保存',
    DELETE: 'Delete',
    SEARCH: '検索'
}

export const HEADER_NAV = {
    HOLDER: {
        label: 'Holder',
        path: ''
    },
    CARD: {
        label: 'カード選択',
        path: ''
    },
    ABOUT_US: {
        label: '会社概要',
        path: ''
    },
    CONTACT_US: {
        label: 'お問い合わせ',
        path: ''
    }
}

export const FOOTER_NAV = {
    TERMS: {
        label: '利⽤規約',
        path: ''
    },
    ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION: {
        label: '特定商取引法に基づく表記',
        path: ''
    },
    PRIVACY_POLICY: {
        label: 'プライバシーポリシー',
        path: ''
    }
}

export const TYPE_GENERAL_SETTING = {
    PRIVACY_POLICY: 'privacy_policy',
    ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION: 'act_of_specified_commercial_transaction',
    TERMS: 'terms',
    ABOUT_US: 'about_us'
};

import { forwardRef, useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import moment from 'moment';
registerLocale('ja', ja);

import './style.scss';
import classNames from 'classnames';

const DateTimeCalendarCustom = (props) => {

    // props
    const { 
        className,
        startDate,
        endDate,
        selectYearFrom = moment().get('year') - 100,
        selectYearTo = moment().get('year'),
        onChange,
        showTimeSelect = false,
        timeIntervals = 15,
        timeCaption = '時間',
        dateFormat,
        minDate,
        maxDate,
        iconInput,
        isEditInput = false
    } = props;

    // custom header
    const years = Array.from({ length: selectYearTo - selectYearFrom + 1 }).map((e, idx) => selectYearTo - idx);
    const months = Array.from({ length: 12 }).map((e, idx) => idx + 1);
    const customHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => (
        <div className='d-flex align-items-center justify-content-between pd-lr-10 mg-b-10 react-datepicker-header-custom'>
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className='rdrNextPrevButton rdrPprevButton'>
                <i/>
            </button>

            <div className='d-flex align-items-center justify-content-between flex-gap-15'>
                <select
                    value={moment(date).get('month') + 1}
                    onChange={({ target: { value } }) =>
                        changeMonth(value - 1)
                    }
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}月
                        </option>
                    ))}
                </select>

                <select
                    value={moment(date).get('year')}
                    onChange={({ target: { value } }) => changeYear(value)}
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className='rdrNextPrevButton rdrNextButton'>
                <i/>
            </button>
        </div>
    )

    // custom day
    const renderDayContents = (day, date) => {
        return <span>{moment(date).format('DD')}</span>;
    };

    // custom input
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='w-100' ref={ref}>
            <input className='w-100' 
                value={value} 
                readOnly={!isEditInput}
                onClick={onClick}
            />
            <div className='btn-icon-input'>
                {iconInput || <i className='fa-solid fa-calendar-days' onClick={onClick}></i> }
            </div>
            {
                value &&
                <div className='btn-icon-input btn-clear-input' onClick={() => onChange(null)}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
            }
        </div>
    ));

    return (
        <DatePicker
            locale='ja'
            calendarClassName={classNames('react-datepicker-custom', className)}
            dateFormat={dateFormat ? dateFormat : showTimeSelect ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd'}
            selected={startDate ? new Date(startDate) : null} 
            onChange={onChange}
            renderCustomHeader={customHeader}
            renderDayContents={renderDayContents}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
            minDate={minDate}
            maxDate={maxDate}
            customInput={<CustomInput/>}
        />
    )
}

export default DateTimeCalendarCustom;
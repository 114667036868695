import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
import RootStore from './stores';
import { ProSidebarProvider } from 'react-pro-sidebar';

// global css
import './index.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={new RootStore()}>
        <ProSidebarProvider>
            <App />
        </ProSidebarProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import ApiStore from './ApiStore';
import AuthStore from './AuthStore';
import ModalStore from './ModalStore';


class RootStore {

    constructor() {
        this.modalStore = new ModalStore(this);
        this.apiStore = new ApiStore(this);
        this.authStore = new AuthStore(this);
    }
}

export default RootStore;
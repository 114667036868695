import { Link } from 'react-router-dom';
import { FOOTER_NAV } from '../../core/configs/constants';

const Footer = props => {
    
    return (
        <div className='footer-container'>
            <div className='fs-heading-normal'>Copyright 2023 - Advance Ability Inc.</div>
            <div className='footer-nav'>
                {
                    Object.keys(FOOTER_NAV).map((key, idx) => (
                        <Link key={idx} to={FOOTER_NAV[key].path} 
                            className='text-white fs-heading-normal font-bold'>
                            {FOOTER_NAV[key].label}
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}

export default Footer;
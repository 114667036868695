import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import yup from '../../core/utils/yupValidate';
import classNames from 'classnames';

const LoginScreen = observer(() => {

    // other
    const navigate = useNavigate();

    // store
    const { authStore: { token, login } } = useStore();

    /// state
    const [ showPassword, setShowPassword ] = useState(false);

    const validateLoginSchema = yup.object().shape({
        email: yup.string().required(MSG['error.required']),
        password: yup.string().required(MSG['error.required'])
    })
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(validateLoginSchema), mode: 'onChange'});

    // function
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const onLogin = async (data) => {
        const res = await login(data);
        if(res) {
            navigate(SYSTEM_PATH.DASHBOARD);
        }
    }

    if(token) {
        return <Navigate to={SYSTEM_PATH.DASHBOARD}/>;
    }

    return(
        <div className='login-screen wrapper-content-form d-flex flex-column align-content-between'>
            <form onSubmit={handleSubmit(onLogin)} className='mg-t-35'>
                <div className='title fs-heading-large text-white font-semi-bold'>
                ログイン
                </div>
                <div className='mg-t-15'>
                    <div className='form-floating input-floating'>
                        <input {...register('email')} id='email' type={'text'} placeholder='email' className='form-control'/>
                        <label htmlFor='email'>メールアドレス</label>
                    </div>
                    {
                        errors?.email &&
                    <div className='text-danger mg-t-5 fs-error'>{errors.email?.message}</div>
                    }
                </div>
                <div className='mg-t-15'>
                    <div className='form-floating input-floating'>
                        <input {...register('password')} id='password' placeholder='password' type={!showPassword ? 'password' : 'text'} 
                            className='form-control input-with-icon' autoComplete='off'/>
                        <label htmlFor='password'>パスワード</label>
                    </div>
                    <button type='button' className='btn-icon-input' onClick={toggleShowPassword}>
                        <i className={classNames('text-white', showPassword ? 'fas fa-eye' : 'fas fa-eye-slash')}/>
                    </button>
                    {
                        errors?.password &&
                            <div className='text-danger mg-t-5 fs-error'>{errors.password?.message}</div>
                    }
                </div>
                <div className='text-end mg-t-20'>
                    <Link to={SYSTEM_PATH.FORGET_PASSWORD} className='hover-underline fs-label'>パスワードを忘れた方はこちら</Link>
                </div>
                <button type='submit' className='btn btn-bg-cs-1 w-100 mg-t-20' disabled={isSubmitting}>ログイン</button>
            </form>
            <div className='form-bottom'>
                <div className='text-center'>
                    <span className='fs-label mg-r-5 text-white'>アカウントをお持ちでないですか?</span> 
                    <Link to={SYSTEM_PATH.REGISTER} className='hover-underline fs-label'>アカウント登録</Link>
                </div>
            </div>
        </div>
    )
})

export default LoginScreen;
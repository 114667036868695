import { Route, Routes } from 'react-router-dom';
import ContainerBody from '../components/Container/ContainerBody';
import NotFoundScreen from '../screens/404/NotFoundScreen';
import { SYSTEM_PATH } from '../core/configs/constants';
import { ContainerBodyOnlyBackground } from '../components';
import LoginScreen from '../screens/Login/LoginScreen';
import HomeScreen from '../screens/Home/HomeScreen';

const Root = () => {
    return (
        <Routes>
            {/* with container only background - public route */}
            {/* <Route element={ <ContainerBodyOnlyBackground/> }>
                <Route path={SYSTEM_PATH.LOGIN} element={<LoginScreen/>} />
            </Route>  */}
                            

            {/* with container body 
            <Route element={ <ContainerBody/> }>
            </Route>  */}
            <Route path={SYSTEM_PATH.HOME} element={<HomeScreen/>} />

            {/* not found */}
            <Route path="*" element={<NotFoundScreen />} />
        </Routes>
    );
};

export default Root;

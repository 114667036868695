const NotFoundScreen = () => {

    return(
        // We could not find the page you were looking for. Meanwhile, you may return to home.
        <div className='not-found-screen'>
            お探しのページは見つかりませんでした。<a href='/'>ホーム画面へ</a>戻れます。
        </div>
    )
}

export default NotFoundScreen;
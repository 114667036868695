import axios from 'axios';

class GlobalApi {

    constructor() {
    }

    call(url, method, headers, data) {
        return axios({
            url,
            method,
            headers,
            data
        })
    }
}

export default GlobalApi;

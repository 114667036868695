import { action, flow, makeObservable, observable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../core/configs/constants';
import { GlobalApi } from '../apis';
import { getStringAfterLastSlash } from '../core/utils/common';

class BaseStore {

    defaultPaging =  {
        page: 1,
        totalPage: 0,
        totalRecord: 0,
        size: DEFAULT_PAGE_SIZE,
        sortKey: null,
        sortDir: null
    }

    paging = this.defaultPaging;

    constructor() {
        makeObservable(this, {
            paging: observable,
            setAttrObservable: action.bound,
            clean: action.bound,
            uploadToAWSS3WithPresignedUrl: flow.bound
        })

        this.globalApi = new GlobalApi();
    }

    *uploadToAWSS3WithPresignedUrl({ url, fields }, file) {
        try {
            const data = new FormData();
            data.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
            data.append('X-Amz-Credential', fields['X-Amz-Credential']);
            data.append('X-Amz-Date', fields['X-Amz-Date']);
            data.append('Policy', fields['Policy']);
            data.append('X-Amz-Signature', fields['X-Amz-Signature']);
            data.append('file', file);


            const res = yield this.globalApi.call(
                `${url}/${fields.key}.${getStringAfterLastSlash(file.name)}`, 
                'PUT', 
                { 'Content-Type': file.type }, 
                data);
            return res;
        } catch (error) {
            console.log(error);
        }
    }

    setAttrObservable(attr, value, isMixed = false, isArray = false) {
        this[attr] = !isMixed ? value : !isArray ? { ...this[attr], ...value } : [ ...this[attr], ...value ];
    }

    clean() {
        this.paging = this.defaultPaging;
    }
}
export default BaseStore;

import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

import './style.scss';

const ContainerBody = observer(() => {

    // other
    const navigate = useNavigate();

    return(
        <div className='wrapper wrapper-body'>
            <div className='wrapper-container'>
                <div className='wrapper-header'>
                    <Header/>
                </div>
                <div className='wrapper-content'>
                    <Outlet/>
                </div>
                <div className='wrapper-footer'>
                    <Footer/>
                </div>
            </div>
        </div>
    )
})

export default ContainerBody;
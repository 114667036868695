const HomeScreen = () => {

    return (
        <div className='home-screen d-flex align-items-center flex-column' 
            style={{ 
                backgroundImage: 'url(/images/banner9-1.png)', 
                width: '100%', 
                height: '100vh', 
                backgroundRepeat: 'no-repeat', 
                backgroundPosition: 'center bottom' }}>
            <h1 className="mg-t-100 font-bold text-center">HỆ THỐNG ĐANG TRONG GIAI ĐOẠN PHÁT TRIỂN</h1>
            <div className="fs-cs-20 text-center">VUI LÒNG QUAY LẠI SAU!</div>
        </div>
    )
}

export default HomeScreen;